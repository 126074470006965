import { useState } from 'react';
import { Text, Flex, Input, HStack, Box } from '@chakra-ui/react';
import { components } from 'react-select';
import { SearchIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';

import { MagnifyIcon, PlusIcon } from '../../../../../../../assets/icons';
import { AutoComplete } from '../../../../../../../components/forms';
import { PurpleButton, SecondaryButton } from '../../../../../../../components/Buttons';
import { OutpostTheme } from '../../../../../../../themes/outpost';

import { AddressBook } from '../../../../../../../generated';
import { AppState } from '../../../../../../../store/root-reducers';
import { formatCurrency } from '../../../../../../../utils';
import { Tag } from '../../../../../../../components/TagComponent';

import { IOrderItemOption } from '../../contexts/CreateOrderContext/useAddOrderTests/useAddOrderTests';
import { useCreateOrderContext } from '../../contexts/CreateOrderContext';
import useTranslationComponent from '../../../../../../../hooks/useTranslationComponent';

interface IProps {
  orderName: string;
  isLoadingOrder: boolean;
  orderItemOptions: IOrderItemOption[];
  onChange: (value: IOrderItemOption) => void;
  defaultAddress?: AddressBook;
  selectedAddress?: AddressBook;
  isClinicalOrder?: boolean;
}

const Control = ({ children, ...rest }) => (
  // @ts-ignore
  <components.Control {...rest}>
    <MagnifyIcon ml={3} fontSize="18px" color="rgba(12,52,104,0.22)" /> {children}
  </components.Control>
);

const Option = ({
  data,
  defaultAddressID,
  selectedAddress,
  ...rest
}: {
  data: IOrderItemOption;
  defaultAddressID?: string;
  selectedAddress?: AddressBook;
}) => {
  const { t } = useTranslationComponent(['phr']);
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const { getOrderItemByOrderItemID } = useCreateOrderContext();

  const orderItem = data.orderItemID ? getOrderItemByOrderItemID(data.orderItemID) : undefined;

  return (
    // @ts-ignore
    <components.Option {...rest}>
      <HStack justifyContent="space-between">
        <HStack flex="1">
          <Text>{orderItem?.item?.name}</Text>
        </HStack>

        {selectedAddress?.associatedID && (
          <HStack width="120px" justifyContent="flex-end">
            <Tag
              text={
                orderItem?.retailAmount
                  ? formatCurrency(
                      orderItem?.retailAmount,
                      orderItem?.currencyEnum,
                      currentUser!.cultureInfo,
                    )
                  : t('Unavailable')
              }
              containerProps={{
                bgColor: defaultAddressID === orderItem?.storeID ? 'rgb(255, 194, 0)' : '#e0f6f4',
                justifyContent: 'center',
              }}
              textProps={{ fontSize: '12px' }}
            />
          </HStack>
        )}
      </HStack>
    </components.Option>
  );
};

export const SearchTestForm = ({
  orderName,
  isLoadingOrder,
  orderItemOptions,
  onChange,
  defaultAddress,
  selectedAddress,
  isClinicalOrder,
}: IProps) => {
  const { t } = useTranslationComponent(['phr']);

  const [newTestForm, setNewTestForm] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');

  const title = t(!description ? 'No medications added.' : '');

  const onChangeSearchInput = (value: string) => {
    setDescription(value);
  };

  const handleSubmit = () => {
    const obj = { description, orderItemID: undefined };
    onChange(obj);
  };

  return (
    <Box>
      {!newTestForm ? (
        <>
          {!isClinicalOrder && (
            <Box mb="3" textAlign="center">
              <SecondaryButton
                size="sm"
                borderRadius="lg"
                leftIcon={<PlusIcon />}
                onClick={() => setNewTestForm(true)}
              >
                {t('Create Custom Test')}
              </SecondaryButton>
            </Box>
          )}
          <Text fontSize={OutpostTheme.FontSizes.body} mb="2">
            {t('Search for')} {orderName}
          </Text>
          <AutoComplete
            isLoading={isLoadingOrder}
            label=""
            isClearable={false}
            placeholder={t('Enter name of test')}
            isMulti={false}
            options={orderItemOptions}
            valueKey="orderItemID"
            labelKey="description"
            name="tests"
            resultLimit={20}
            onChange={onChange}
            noOptionsMessage={() => t('No Tests found')}
            components={{
              Control,
              Option: (props) => (
                <Option
                  {...props}
                  defaultAddressID={defaultAddress?.associatedID}
                  selectedAddress={selectedAddress}
                />
              ),
            }}
            menuPortalTarget={document.body}
            onInputChangeCallback={onChangeSearchInput}
          />
          <Text mb={10} fontSize="12px">
            {t('Select the test you wish to order and send it to any facility of your choice')}
          </Text>
        </>
      ) : (
        <>
          <Flex justify="space-between">
            <Text fontSize={OutpostTheme.FontSizes.body} mb={4}>
              {t('Create New')} {orderName}
            </Text>
            <Text
              onClick={() => setNewTestForm(false)}
              cursor="pointer"
              color="red.500"
              fontSize="xs"
            >
              {t('Cancel')}
            </Text>
          </Flex>
          <Flex
            alignItems="center"
            background="#FFFFFF"
            border="1px solid #E4E9F2"
            borderRadius="0.25rem"
            paddingLeft={3}
            width={'100%'}
          >
            <SearchIcon color="gray.300" />
            <Input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              border="none"
              focusBorderColor="none"
              background="none !important"
              type="text"
              placeholder={t('Enter name of test')}
            />
          </Flex>
          <Text fontWeight="400" my={4} fontSize="12px">
            <Text as="span" fontWeight="500">
              {t('Note')}:
            </Text>
            {t(
              "You are about to order a test that is not within Neo's database. Please provide more information about this test. This test will be sent directly to the patient only and not to a laboratory or diagnostic center.",
            )}
          </Text>
          <Flex justifyContent="flex-end">
            <PurpleButton disabled={!description} title={title} onClick={handleSubmit}>
              {t('Continue')}
            </PurpleButton>
          </Flex>
        </>
      )}
    </Box>
  );
};
