import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useProviderSchedulesSearch } from '../../../services/providerSchedules/queries/useProviderSchedulesSearch';
import { AppState } from '../../../store/root-reducers';
import { ButtonRow, PrimaryButton } from '../../Buttons';
import moment from 'moment';
import { CenteredLoadingIndicator } from '../../LoadingIndicator';
import { HealRoutes, TimeFormatEnum } from '../../../constants';
import { push } from 'connected-react-router';
import { CategoryIcon, ClinicIcon } from '../../../assets/icons';
import styled from 'styled-components';
import { CloseIconButton } from '../../Modals';
import useTranslationComponent from '../../../hooks/useTranslationComponent';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

enum StepsEnum {
  Welcome,
  Schedule,
}

interface StoreSchedule {
  storeName: string;
  storeID: string;
  schedules: Schedule[];
}

interface Schedule {
  providerScheduleID: string;
  startDate: Date;
  endDate: Date;
}

const ProviderStartDayModal = ({ isOpen, onClose }: IProps) => {
  const { t } = useTranslationComponent(['dashboard-provider']);
  const dispatch = useDispatch();
  const [step, setStep] = useState<StepsEnum>(StepsEnum.Welcome);
  const provider = useSelector((state: AppState) => state.providerState.provider);
  const [storeSchedules, setStoreSchedules] = useState<StoreSchedule[]>();

  const { isLoadingProviderSchedules, providerSchedules } = useProviderSchedulesSearch({
    providerID: provider?.providerID,
    startDate: moment().startOf('d').toDate(),
    endDate: moment().endOf('d').toDate(),
  });

  useEffect(() => {
    const storeSchedules: StoreSchedule[] = [];
    (providerSchedules ?? []).forEach((x) => {
      const { startDate, endDate, providerScheduleID } = x;

      // Get Stores
      const stores = {};
      (x.providerScheduleStoreItems ?? []).forEach((y) => {
        const storeID = y.storeItem?.storeID || '';
        stores[storeID] = y.storeItem?.store?.name;
      });

      // Add / Update store
      (Object.keys(stores) ?? []).forEach((storeID) => {
        // Check if already in Array
        const existingStoreScheduleIndex = storeSchedules.findIndex((ss) => ss.storeID === storeID);
        if (existingStoreScheduleIndex > -1) {
          storeSchedules[existingStoreScheduleIndex].schedules.push({
            startDate,
            endDate,
            providerScheduleID,
          });
        } else {
          storeSchedules.push({
            storeID,
            storeName: stores[storeID],
            schedules: [{ startDate, endDate, providerScheduleID }],
          });
        }
      });
    });
    setStoreSchedules(storeSchedules);
  }, [providerSchedules]);

  return (
    <ChakraModal size="lg" isOpen={isOpen} onClose={onClose} variant="borderless">
      <ModalOverlay />
      <ModalContent>
        <CloseIconButton onClick={onClose} />
        {/* <ModalCloseButton top={6} right={6} /> */}
        {step === StepsEnum.Welcome && (
          <Grid templateColumns={'repeat(2, 1fr)'}>
            <GridItem colSpan={{ sm: 0 }}>
              <ImageWrapper
                objectFit="cover"
                borderLeftRadius="1.5rem"
                src="https://neoresources.blob.core.windows.net/images/doctor-tools.png"
              />
            </GridItem>
            <GridItem display="flex">
              <ModalBody>
                <Box py={20} px={10}>
                  <Heading mb={4} size="sm">
                    {t('Welcome back')}
                  </Heading>
                  <Text mb={10}>{t('What would you like to do today?')}</Text>
                  <OptionButton
                    icon={<ClinicIcon fontSize="18px" />}
                    onClick={() => setStep(StepsEnum.Schedule)}
                    text={t('Go to My Clinics')}
                    buttonProps={{ mb: 5 }}
                  />
                  <OptionButton
                    icon={<CategoryIcon fontSize="18px" />}
                    onClick={onClose}
                    text={t('Go to Dashboard')}
                  />
                </Box>
              </ModalBody>
            </GridItem>
          </Grid>
        )}
        {step === StepsEnum.Schedule && (
          <>
            <ModalBody>
              <Box py={16} px={20}>
                <Heading mb={4} size="sm">
                  {t('Hello')} {provider?.fullName}
                </Heading>
                <Text mb={10}>{t('You are scheduled to work during the following shift.')}</Text>
                {isLoadingProviderSchedules ? (
                  <CenteredLoadingIndicator />
                ) : (providerSchedules ?? []).length === 0 ? (
                  <Text fontSize="20px" textAlign="center">
                    {t('You have no Schedules today!')}
                  </Text>
                ) : (
                  <Grid templateColumns={'repeat(2, 1fr)'} columnGap={10} rowGap={5}>
                    {(storeSchedules ?? []).map((x) => (
                      <GridItem key={x.storeID}>
                        <Text fontSize="1rem" fontWeight="500" mb={4}>
                          {x.storeName}
                        </Text>
                        <Stack spacing={4}>
                          {x.schedules.map((y) => (
                            <Flex
                              key={y.providerScheduleID}
                              border="1px solid"
                              borderColor="gray.200"
                              px={5}
                              py={6}
                              borderRadius="0.75rem"
                            >
                              <Text fontSize="1rem">
                                {moment(y.startDate).format(TimeFormatEnum.TIME)} -{' '}
                                {moment(y.endDate).format(TimeFormatEnum.TIME)}
                              </Text>
                            </Flex>
                          ))}
                        </Stack>
                      </GridItem>
                    ))}
                  </Grid>
                )}
              </Box>
            </ModalBody>
            <ModalFooter>
              <ButtonRow>
                <PrimaryButton onClick={() => dispatch(push(HealRoutes.WaitingRoom))}>
                  {t('Go to My Clinics')}
                </PrimaryButton>
              </ButtonRow>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </ChakraModal>
  );
};

export default ProviderStartDayModal;

const ImageWrapper = styled(Image)`
  height: 454px;
  @media (max-width: 479px) {
    height: 100vh;
  }
`;

interface IOptionButton {
  text: string;
  onClick: () => void;
  buttonProps?: ButtonProps;
  icon: ReactNode;
}
const OptionButton = ({ text, onClick, buttonProps, icon }: IOptionButton) => {
  return (
    <Button
      variant="unstyled"
      height="unset"
      display="flex"
      onClick={onClick}
      bg="transparent"
      w="280px"
      align="center"
      p={4}
      alignItems="center"
      border="1px solid"
      borderColor="gray.100"
      borderRadius="0.75rem"
      {...buttonProps}
    >
      <Flex
        borderRadius="full"
        bg="#E8F1FD"
        h="40px"
        minW="40px"
        w="40px"
        mr={3}
        alignItems="center"
        justifyContent="center"
      >
        {icon}
      </Flex>
      <Text fontSize="1rem" flexGrow={1} textAlign="left">
        {text}
      </Text>
      <ChevronRightIcon color="gray.500" ml={3} w="20px" h="20px" />
    </Button>
  );
};
