import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { HealRoutes, WalletRoutes } from '../../../constants';
import { UserDetailSetting, UserDetailSettingEnum } from '../../../generated';
import { useIsMarketingPartner } from '../../../hooks/useIsMarketingPartner';
import useTranslationComponent from '../../../hooks/useTranslationComponent';
import { AppState } from '../../../store/root-reducers';
import { closeSideMenu, openSideMenu } from '../../../store/sideMenu/sideMenuActions';
import { getUserDetailSettings } from '../../../store/userDetailSettings/getUserDetailSettingsAction';
import { updateUserDetailSettings } from '../../../store/userDetailSettings/updateUserDetailSettingsAction';
import { PrimaryButton } from '../../Buttons/PrimaryButton';
import TooltipComponent from '../Tooltip';
import PatientStartDayModal from './PatientStartDayModal';

interface IProps {
  includeWelcomeMessage?: boolean;
}

const PatientDashboardTour = ({ includeWelcomeMessage = false }: IProps) => {
  const { t } = useTranslationComponent(['tour']);

  const [stepIndex, setStepIndex] = useState<number>(0);
  const dispatch = useDispatch();
  const userDetailSettings = useSelector(
    (state: AppState) => state.userDetailSettingState.userDetailSettings,
  );

  const [run, setRun] = useState<boolean>(false);
  const [showIntroModal, setShowIntroModal] = useState<boolean>(false);

  const isMarketingPartner = useIsMarketingPartner();

  const isSmallScreen = window.innerWidth < 1366;

  const globalSteps: Step[] = [
    {
      target: 'body',
      content: t(
        `This is your main dashboard where you are no more than a single step away from your health tools. From here you can access your personal health records, arrange a consultation with a health provider, help arrange payment for health visits or goods and see upcoming events specific to your needs.`,
      ),
      placement: 'center' as const,
    },
    {
      target: '#top-bar-profile',
      content: t(
        'My Profile is where you can build your secure health identity, identify your family relations or dependents, and add information about your insurer.',
      ),
      placement: 'top' as const,
    },
    {
      target: '#side-bar-wallet',
      content: t(
        'Your Wallet is a protected and easy to use way for you to pay and access funds that are earmarked for the medical needs of you or your loved ones. Use your existing bank, credit card or USSD phone payment system to safely pay for products and services.',
        {
          type: isMarketingPartner ? ' ' : ' Health ',
          partner: isMarketingPartner ? ' ' : ' medical ',
        },
      ),
      placement: 'top' as const,
    },
    {
      target: '#see-provider',
      content: t(
        `Any place you there is a See a provider button it will connect you directly to our appointments system where you can quickly find either a health provider in your area or connect you to our global network of health professionals who you can meet via HD quality video and audio.`,
      ),
      placement: 'top' as const,
    },
    {
      target: '#medical-records',
      content: t(
        `Securely manage your personal health records so that you and your provider can be up to date with your current conditions including your previous procedures, health trackers, medications, surgeries and other procedures. Neo is 100% HIPAA compliant.`,
        { Neo: process.env.REACT_APP_COMPANY_NAME },
      ),
      placement: 'top' as const,
    },
    {
      target: '#careteam',
      content: t(`Every time you see a provider they will be added to your Care Team.`),
      placement: 'top' as const,
    },
    {
      target: '#carespaces',
      content: t(
        `Care Spaces are discussion groups that you are active in where you can get peer information from fellow patients and experts.`,
      ),
      placement: 'top' as const,
    },
    {
      target: '#appointments',
      content: t(
        `Use the calendar to see keep up to date on your upcoming appointments or health alerts.`,
      ),
      placement: 'top' as const,
    },
  ];

  const smallScreenSteps: Step[] = [
    {
      target: isMarketingPartner ? '#no-target' : '#patient-side-bar',
      content: t(
        `The Heal, Learn and help center are where you can find additional tools that will help you navigate your healing journey, keep tabs on your well-being, and proactively seek information on improving your mind and body.`,
      ),
      placement: 'center' as const,
    },
    {
      target: '#top-bar-reward',
      content: t(
        `Members are rewarded for completing certain tasks and for participating in Neo Health promotions. Click here to see current campaigns or status of rewards you've earned`,
      ),
      placement: 'top' as const,
    },
    {
      target: '#top-bar-messages',
      content: t(
        `See any messages sent to you from your health providers. The box will light up when there are new communications.`,
      ),
      placement: 'top' as const,
    },
    {
      target: '#top-bar-shopping-cart',
      content: t(
        `Clinical Cart helps you keep track of all purchases you have made within Neo Health including medications, appointments or procedures.`,
      ),
      placement: 'top' as const,
    },
  ];

  const largeScreenSteps: Step[] = [
    {
      target: isMarketingPartner ? '#no-target' : '#patient-side-bar',
      content: t(
        `The Heal, Learn and help center are where you can find additional tools that will help you navigate your healing journey, keep tabs on your well-being, and proactively seek information on improving your mind and body.`,
      ),
      placement: 'auto' as const,
    },
    {
      target: '#top-bar-reward',
      content: t(
        `Members are rewarded for completing certain tasks and for participating in Neo Health promotions. Click here to see current campaigns or status of rewards you've earned`,
      ),
      placement: 'top' as const,
    },

    {
      target: '#top-bar-messages',
      content: t(
        `See any messages sent to you from your health providers. The box will light up when there are new communications.`,
      ),
      placement: 'top' as const,
    },

    {
      target: '#top-bar-shopping-cart',
      content: t(
        `Clinical Cart helps you keep track of all purchases you have made within Neo Health including medications, appointments or procedures.`,
      ),
      placement: 'top' as const,
    },
  ];

  const steps: Step[] = [
    ...(includeWelcomeMessage
      ? [
          {
            target: 'body',
            placement: 'center' as const,
            content: t(
              `Welcome to your Neo web home. Your new circle of care on-line where you can meet directly with doctors from your country and around the world using our advanced video platform or find a provider in-person, control your health information securely and get the best current information to support your health goals no matter where you are.`,
              { Neo: process.env.REACT_APP_COMPANY_NAME },
            ),
          },
        ]
      : []),

    ...globalSteps,

    ...(isSmallScreen ? smallScreenSteps : largeScreenSteps),
    {
      target: 'body',
      content: (
        <LastStep>
          {t(
            "That's your dashboard. If you want, you can tell us what you would like to do next and we can guide you to those places.",
          )}
          <br />
          <NavigationButton
            onClick={() => {
              completeTour();
              dispatch(push(HealRoutes.BookConsult));
            }}
          >
            {t('I want to see a health provider')}
          </NavigationButton>
          <NavigationButton
            onClick={() => {
              completeTour();
              dispatch(push(HealRoutes.HealthRecords));
            }}
          >
            {t('I want to store my health information')}
          </NavigationButton>
          <NavigationButton
            onClick={() => {
              completeTour();
              dispatch(push(WalletRoutes.WalletBalance));
            }}
          >
            {t('I want to pay for services and health goods')}
          </NavigationButton>
        </LastStep>
      ),
      placement: 'center' as const,
    },
  ];

  const completeTour = () => {
    const updatedUserDetailSettings = (userDetailSettings ?? []).map((x) => {
      const updated = new UserDetailSetting();
      updated.init(x);
      if (x.userDetailSettingEnum === UserDetailSettingEnum.PatientDashboardTour) {
        updated.value = 'true';
      }
      return updated;
    });
    setRun(false);
    dispatch(updateUserDetailSettings(updatedUserDetailSettings));
  };

  const advance = (data: CallBackProps) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)) {
      completeTour();
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { index } = data;

    switch (index) {
      case 2: {
        if (isSmallScreen) {
          dispatch(openSideMenu());
        }
        advance(data);
        break;
      }
      case 10: {
        if (isSmallScreen) {
          dispatch(closeSideMenu());
        }
        advance(data);
        break;
      }
      default: {
        advance(data);
      }
    }
  };

  useEffect(() => {
    if (!userDetailSettings) {
      dispatch(getUserDetailSettings());
    }
  }, [dispatch, userDetailSettings]);

  useEffect(() => {
    if (userDetailSettings) {
      const hasTakenTour = userDetailSettings.find(
        (x) => x.userDetailSettingEnum === UserDetailSettingEnum.PatientDashboardTour,
      );
      if (!hasTakenTour || hasTakenTour.value === 'false') {
        setRun(true);
      } else {
        setShowIntroModal(true);
      }
    }
  }, [userDetailSettings]);

  return (
    <>
      <Joyride
        disableOverlayClose
        disableCloseOnEsc
        callback={handleJoyrideCallback}
        showSkipButton
        run={run}
        locale={{
          back: t('Back'),
          close: t('Close'),
          last: t('Done'),
          next: t('Next'),
          skip: t('Skip'),
        }}
        stepIndex={stepIndex}
        steps={steps}
        continuous
        tooltipComponent={(props) => TooltipComponent({ ...props })}
        styles={{
          options: {
            zIndex: 9999,
          },
        }}
      />
      <PatientStartDayModal isOpen={showIntroModal} onClose={() => setShowIntroModal(false)} />
    </>
  );
};

export default PatientDashboardTour;

const LastStep = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavigationButton = styled(PrimaryButton)`
  margin-top: 15px;
`;
