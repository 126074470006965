import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  HStack,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { HealRoutes, WalletRoutes } from '../../../constants';
import { CategoryIcon, ClinicIcon, WalletIcon } from '../../../assets/icons';
import styled from 'styled-components';
import useTranslationComponent from '../../../hooks/useTranslationComponent';
import { useUrlQuery } from '../../../utils/hooks/useUrlQuery';
import { useDispatch, useSelector } from 'react-redux';
import { setPatientLoginPurpose } from '../../../store/patient/patientActions';
import { PatientLoginPurposeEnum } from '../../../store/patient/patientReducers';
import { addLocalStorageItems } from '../../../utils/localStorageActions';
import { AppState } from '../../../store/root-reducers';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const PatientStartDayModal = ({ isOpen, onClose }: IProps) => {
  const { t } = useTranslationComponent(['dashboard']);

  const patient = useSelector((state: AppState) => state.patientState.patient);
  const { routeWithQuery } = useUrlQuery();
  const dispatch = useDispatch();

  const handleSendFunds = () => {
    dispatch(setPatientLoginPurpose(PatientLoginPurposeEnum.SEND_FUNDS));
    addLocalStorageItems([
      { key: 'loginPurpose', value: PatientLoginPurposeEnum.SEND_FUNDS.toString() },
    ]);

    routeWithQuery({ from: 'welcome' }, WalletRoutes.Wallet);
  };

  const handleBookAppointment = () => {
    routeWithQuery({ from: 'welcome' }, HealRoutes.BookConsult);
  };

  return (
    <ChakraModal size="lg" isOpen={isOpen} onClose={() => {}} variant="borderless">
      <ModalOverlay />
      <ModalContent>
        <Grid templateColumns={'repeat(2, 1fr)'}>
          <GridItem colSpan={{ sm: 0 }}>
            <ImageWrapper
              objectFit="cover"
              borderLeftRadius="1.5rem"
              src="https://neoresources.blob.core.windows.net/images/doctor-tools.png"
            />
          </GridItem>
          <GridItem display="flex">
            <ModalBody>
              <Box py={10} px={8}>
                <Heading mb={3} size="sm">
                  {t('Welcome back')}{' '}
                  <Text as="span" fontWeight="bold">
                    {patient?.firstName},
                  </Text>
                </Heading>
                <Text mb={10}>{t('What would you like to do today?')}</Text>
                <OptionButton
                  icon={<ClinicIcon fontSize="18px" />}
                  onClick={handleBookAppointment}
                  text={t('Book a Visit')}
                  buttonProps={{ mb: 5, height: { base: 'auto', lg: '85px' } }}
                  description={t('Schedule a consultation with a provider.')}
                />
                <OptionButton
                  icon={<WalletIcon fontSize="18px" />}
                  onClick={handleSendFunds}
                  text={t('Fund a Health Wallet')}
                  buttonProps={{ mb: 5, height: { base: 'auto', lg: '85px' } }}
                  description={t('Send money to your wallet or family members.')}
                />
                <OptionButton
                  icon={<CategoryIcon fontSize="18px" />}
                  onClick={onClose}
                  text={t('Go to Dashboard')}
                  buttonProps={{ height: { base: 'auto', lg: '85px' } }}
                  description={t('Perform other tasks.')}
                />
              </Box>
            </ModalBody>
          </GridItem>
        </Grid>
      </ModalContent>
    </ChakraModal>
  );
};

export default PatientStartDayModal;

const ImageWrapper = styled(Image)`
  height: 500px;
  @media (max-width: 479px) {
    height: 100vh;
  }
`;

interface IOptionButton {
  text: string;
  onClick: () => void;
  buttonProps?: ButtonProps;
  icon: ReactNode;
  description?: string;
}
const OptionButton = ({ text, onClick, buttonProps, icon, description }: IOptionButton) => {
  return (
    <Button
      variant="unstyled"
      height="unset"
      onClick={onClick}
      bg="transparent"
      minW="280px"
      w="100%"
      maxW="100%"
      p={2}
      border="1px solid"
      borderColor="gray.100"
      borderRadius="0.75rem"
      {...buttonProps}
    >
      <Flex alignItems="center" w="full" maxW="100%" overflow="hidden">
        {/* <Box mr="auto" flexGrow={1}> */}
        <HStack mr="auto" flexGrow={1}>
          <Flex
            borderRadius="full"
            bg="#E8F1FD"
            h="40px"
            minW="40px"
            w="40px"
            mr={2}
            alignItems="center"
            justifyContent="center"
          >
            {icon}
          </Flex>
          <Flex flexDirection="column" flexGrow={1}>
            <Text fontSize="1rem" textAlign="left" whiteSpace="normal">
              {text}
            </Text>
            {description && (
              <Text
                fontSize="0.75rem"
                color="rgb(65, 79, 101)"
                textAlign="left"
                whiteSpace="normal"
              >
                {description}
              </Text>
            )}
          </Flex>
        </HStack>
        {/* </Box> */}
        <ChevronRightIcon color="gray.500" ml={3} w="20px" h="20px" />
      </Flex>
    </Button>
  );
};
