import {
  Box,
  HStack,
  Image,
  Link,
  LinkProps,
  List,
  ListItem,
  ListItemProps,
  Stack,
  StackItem,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { getCurrentDate, getYear } from '../../../../utils/getDates';

type ListItemLinkProps = {
  href: string;
  children: ReactNode;
  containerProps?: ListItemProps;
  linkProps?: LinkProps;
};

const ListItemLink: FC<ListItemLinkProps> = (props) => (
  <ListItem mb="4" {...props.containerProps}>
    <Link
      fontSize="14px"
      color="white"
      textDecoration="underline"
      _hover={{
        textDecoration: 'underline',
      }}
      _active={{
        textDecoration: 'underline',
      }}
      _focus={{
        textDecoration: 'underline',
      }}
      href={props.href}
      {...props.linkProps}
    >
      {props.children}
    </Link>
  </ListItem>
);

const PublicSearchFooter: FC = () => {
  return (
    <Box bgColor="#000000" color="white">
      <Box maxW="75rem" mx="auto">
        <Box pb="5rem" pt="7.5rem" px="3">
          <Stack
            flexDirection={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'center', md: 'flex-start' }}
            textAlign={{ base: 'center', md: 'left' }}
          >
            <StackItem flex="1" px={{ md: '4', lg: '8' }} pb={{ base: '8', md: '0' }}>
              <HStack mb="6" justifyContent={{ base: 'center', md: 'flex-start' }}>
                <Link href="https://www.neo.health">
                  <Image
                    src="https://uploads-ssl.webflow.com/5ddece91214fad1536887f35/61847f57e45d1a1a847aadc9_Group%2014478.svg"
                    alt="Neo Health"
                  />
                </Link>
              </HStack>
              <VStack alignItems={{ base: 'center', md: 'flex-start' }} spacing="3">
                <Text>Healthcare, Funds And Records At Your Fingertips</Text>
                <Text fontWeight="bold">GET IN TOUCH</Text>
                <Link
                  href="mailto:hello@neo.health"
                  color="white"
                  textDecoration="none"
                  _hover={{
                    textDecoration: 'underline',
                  }}
                  _active={{
                    textDecoration: 'underline',
                  }}
                  _focus={{
                    textDecoration: 'underline',
                  }}
                >
                  hello@neo.health
                </Link>
                <Link
                  href="tel:+2348132723155"
                  color="white"
                  textDecoration="none"
                  _hover={{
                    textDecoration: 'underline',
                  }}
                  _active={{
                    textDecoration: 'underline',
                  }}
                  _focus={{
                    textDecoration: 'underline',
                  }}
                >
                  Whatsapp +234 813 272 3155
                </Link>
              </VStack>
            </StackItem>
            <StackItem px={{ md: '4', lg: '8' }} pb={{ base: '4', md: '0' }}>
              <Text mb="4" fontWeight="bold">
                OUR PLATFORM
              </Text>
              <List>
                <ListItemLink href="https://www.neo.health/provider">Providers</ListItemLink>
                <ListItemLink href="https://www.neo.health/patients">Patients</ListItemLink>
                <ListItemLink href="https://www.neo.health/payments">Health Wallet</ListItemLink>
                <ListItemLink href="https://www.neo.health/covid19">COVID-19 Testing</ListItemLink>
                <ListItemLink href="https://app.neo.health/directory">
                  Provider Directory
                </ListItemLink>
              </List>
            </StackItem>
            <StackItem px={{ md: '4', lg: '8' }} pb={{ base: '4', md: '0' }}>
              <Text mb="4" fontWeight="bold">
                OUR COMPANY
              </Text>
              <List>
                <ListItemLink href="https://www.neo.health/blog-360">Blog</ListItemLink>
                <ListItemLink href="https://www.neo.health/our-partners">Our Partners</ListItemLink>
                <ListItemLink href="https://www.neo.health/privacy">Privacy Policy</ListItemLink>
                <ListItemLink href="https://www.neo.health/terms">
                  Terms &amp; Conditions
                </ListItemLink>
              </List>
            </StackItem>
            <StackItem px={{ md: '4', lg: '8' }}>
              <Text mb="4" fontWeight="bold">
                FOLLOW US
              </Text>
              <Wrap spacing="4">
                <WrapItem>
                  <Link href="https://www.facebook.com/outposthealth/?ref=page_internal" isExternal>
                    <Image
                      src="https://uploads-ssl.webflow.com/5ddece91214fad1536887f35/61847f57e45d1af11d7aadcc_fb%20icon.svg"
                      height={{ base: '30px', lg: '40px' }}
                      alt="Facebook"
                    />
                  </Link>
                </WrapItem>
                <WrapItem>
                  <Link href="https://twitter.com/OutpostHealth" isExternal>
                    <Image
                      src="https://uploads-ssl.webflow.com/5ddece91214fad1536887f35/61847f57e45d1a795f7aadca_Group%2014484.svg"
                      height={{ base: '30px', lg: '40px' }}
                      alt="Twitter"
                    />
                  </Link>
                </WrapItem>
                <WrapItem>
                  <Link href="https://www.instagram.com/outposthq/" isExternal>
                    <Image
                      src="https://uploads-ssl.webflow.com/5ddece91214fad1536887f35/61847f57e45d1a44547aadcb_Group%2014485.svg"
                      height={{ base: '30px', lg: '40px' }}
                      alt="Instagram"
                    />
                  </Link>
                </WrapItem>
                <WrapItem>
                  <Link href="https://www.linkedin.com/company/outpost-healthcare/" isExternal>
                    <Image
                      src="https://uploads-ssl.webflow.com/5ddece91214fad1536887f35/61abc88b67d604186bdbd141_Linked-in.svg"
                      height={{ base: '30px', lg: '40px' }}
                      alt="LinkedIn"
                    />
                  </Link>
                </WrapItem>
              </Wrap>
            </StackItem>
          </Stack>
        </Box>
        <Box py="10" borderTop="1px solid rgba(255, 255, 255, 0.25)">
          <Text color="white" textAlign="center">
            Copyright {getYear(getCurrentDate())} | {process.env.REACT_APP_COPYRIGHT_TEXT}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export { PublicSearchFooter };
