import { useBreakpointValue } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

import { getMobileOS } from '../../../utils/getMobileOS';
import { openUriSchemeWithHyperlinkFallback } from '../../../utils/openUriSchemeWithHyperlinkFallback';

const storeUrls = {
  patient: {
    android: 'https://play.google.com/store/apps/details?id=health.outpost.ohappandroid',
    ios: 'https://apps.apple.com/ca/app/outpost-health/id1570922119',
  },
  provider: {
    android: 'https://play.google.com/store/apps/details?id=health.outpost.ohappandroidsentinel',
    ios: 'https://apps.apple.com/ca/app/outpost-pro-for-providers/id1562427700',
  },
};

const mobileAppSchemaUrls = {
  patient: 'outposthealth://',
  provider: 'outpostpro://',
};

const mobileAppRedirectUrls = {
  patient: {
    devapp: 'https://devlink.neo.health',
    app: 'https://link.neo.health',
  },
  provider: {
    devapp: 'https://outpostprovider.test-app.link',
    app: 'https://outpostprovider.app.link',
  },
};

type UserType = keyof typeof mobileAppSchemaUrls;

const mobileOS = getMobileOS();

const timeoutMs = 1 * 1000;
// const focusInTimeoutSeconds = 6;

const windowHasFocus = () => document.visibilityState === 'visible' && document.hasFocus();
const windowRedirect = (url: string) => {
  window.location.href = url;
};

const useMobileRedirect = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [redirectToStore, setRedirectToStore] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // let focusCountdown = useRef(focusInTimeoutSeconds);

  const isAndroidDevice = isMobile && mobileOS === 'android';

  const isIosDevice = isMobile && mobileOS === 'ios';

  const subdomain = window.location.hostname.split('.')[0];

  const onChangeRedirectToStore = (state: boolean) => {
    setRedirectToStore(state);
  };

  const handleOpenApp = useCallback(
    (user: UserType) => {
      if (!window) return;

      if (subdomain === 'devapp') {
        windowRedirect(mobileAppRedirectUrls[user].devapp);
      } else {
        windowRedirect(mobileAppRedirectUrls[user].app);
      }
    },
    [subdomain],
  );

  const handleOpenPatientApp = useCallback(() => {
    handleOpenApp('patient');
  }, [handleOpenApp]);

  const handleOpenProviderApp = useCallback(() => {
    handleOpenApp('provider');
  }, [handleOpenApp]);

  const handleRedirectOnMobile = useCallback(
    (user: UserType) => {
      if (!window || !document) return;

      if (!isAndroidDevice && !isIosDevice) return;

      setIsLoading(true);

      const storeUrl = isAndroidDevice ? storeUrls[user].android : storeUrls[user].ios;

      let openStoreTimeoutID: ReturnType<typeof setTimeout> | undefined = undefined;
      let stopLoadingTimeoutID: ReturnType<typeof setTimeout> | undefined = undefined;
      // let focusCountdownIntervalID: ReturnType<typeof setInterval> | undefined = undefined;

      const clearOpenStoreTimeout = () => {
        if (openStoreTimeoutID) {
          clearTimeout(openStoreTimeoutID);
          openStoreTimeoutID = undefined;
        }
      };

      // const checkIfFocusAndRedirect = () => {
      //   if (focusCountdown.current <= 0) return;

      //   if (windowHasFocus()) {
      //     windowRedirect(storeUrl);
      //   }
      // };

      // window.addEventListener('focus', checkIfFocusAndRedirect);
      window.addEventListener('blur', clearOpenStoreTimeout);
      window.addEventListener('visibilitychange', clearOpenStoreTimeout);

      if (isAndroidDevice) {
        windowRedirect(mobileAppSchemaUrls[user]);
      } else {
        windowRedirect(storeUrl);
      }

      // focusCountdownIntervalID = setInterval(() => {
      //   if (focusCountdown.current <= 0) {
      //     focusCountdownIntervalID && clearInterval(focusCountdownIntervalID);
      //     focusCountdownIntervalID = undefined;
      //     return;
      //   }

      //   focusCountdown.current -= 1;
      // }, 1000);

      openStoreTimeoutID = setTimeout(() => {
        if (windowHasFocus()) {
          windowRedirect(storeUrl);
        }
      }, timeoutMs);

      stopLoadingTimeoutID = setTimeout(() => {
        setIsLoading(false);
      }, timeoutMs);

      return () => {
        if (openStoreTimeoutID) clearTimeout(openStoreTimeoutID);
        if (stopLoadingTimeoutID) clearTimeout(stopLoadingTimeoutID);

        // focusCountdownIntervalID && clearInterval(focusCountdownIntervalID);

        // window.removeEventListener('focus', checkIfFocusAndRedirect);
        window.removeEventListener('blur', clearOpenStoreTimeout);
        window.removeEventListener('visibilitychange', clearOpenStoreTimeout);
      };
    },
    [isAndroidDevice, isIosDevice],
  );

  const handleRedirectOnMobileNoDelay = useCallback(() => {
    if (!window) return;

    if (!isAndroidDevice && !isIosDevice) return;

    const storeUrl = isAndroidDevice ? storeUrls.patient.android : storeUrls.patient.ios;

    openUriSchemeWithHyperlinkFallback(mobileAppSchemaUrls.patient, storeUrl);
  }, [isAndroidDevice, isIosDevice]);

  return {
    redirectToStore,
    isMobile,
    isAndroidDevice,
    isIosDevice,
    isLoading,
    onChangeRedirectToStore,
    handleRedirectOnMobile,
    handleOpenPatientApp,
    handleOpenProviderApp,
    handleRedirectOnMobileNoDelay,
  };
};

export { storeUrls, mobileAppSchemaUrls, mobileAppRedirectUrls, useMobileRedirect };
