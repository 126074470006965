import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/root-reducers';
import Modal from '../../../components/Modals/Modal';
import { Paragraph } from '../../../components/common/Paragraph';
import useTranslationComponent from '../../../hooks/useTranslationComponent';

const AppVersionMissmatchModal = () => {
  const { t } = useTranslationComponent();
  const showMissmatchModal = useSelector(
    (state: AppState) => state.appVersionState.showMissmatchModal,
  );

  return (
    <Modal
      isOpen={showMissmatchModal}
      title={t('Neo Update', { name: process.env.REACT_APP_COMPANY_NAME ?? 'Neo' })}
      onSubmit={() => window.location.reload()}
      submitText={t('Reload')}
    >
      <Paragraph>
        {t(
          'Neo has updated since the last time you logged in. Please reload the page to enjoy the latest features!',
          {
            name: process.env.REACT_APP_COMPANY_NAME,
          },
        )}
      </Paragraph>
    </Modal>
  );
};

export default AppVersionMissmatchModal;
